import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import figureFormatter           from "@/services/utils/figureFormatter";
import { useStore } from "vuex";
import useDate from '@/services/utils/day';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const pdfPrinter = () => {
    const { formatDate } = useDate();
    const store = useStore();
    const { commaFormat } = figureFormatter();

    const userName = store.state.user.user.name;

    const exportToPDF = (company, data, payload) => {

        var doc = {
            pageSize: 'A4',
            pageMargins: [ 30, 100, 30, 80 ],
            pageOrientation: 'landscape',
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(data, payload),

            
            styles : {
                header: {
                    fontSize: 24
                }
            },

            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: 'Money Receipt Statements'
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (data, payload) => {
        return [
            {
                text: 'Money Receipt Statements',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                decoration: 'underline'
            },

            {
                stack: [
                    {
                        text: `Date: ${payload.startDate} - ${payload.endDate}`
                    },

                    {
                        text: `${payload.createdBy ? 'Created By: ' + payload.createdBy : ''}`
                    }
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['auto', 50, 50, 50, 50, 50, 50, 60, 40, 65, 50, 50, 50],
                    body: data
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },
        ]
    }

    const getHeader = (company) => {
        return {
            margin: [ 30, 25, 30, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60,
                    maxWidth: 60
                },

                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email
                    ]
                }
            ]
        }
    }

    const getFooter = (userName) =>{
        return {
            margin: [ 25, 0 ],
            columns: [
                {
                    columns: [
                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]
                },

                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    return {
        exportToPDF
    }
}

export default pdfPrinter;
