<template>
  <div class="card">

    <div class="bg-blue-light p-1">
      <TitleButton
          :showBtn="false"
          :showAddNew="false"
          title="Money Receipt Statements"
      />
    </div>

    <div class="my-2 px-2">
      <DateQuerySetter
          btnTitle="Go"
          @onClickGo="getSalesReportGeneral"
      >
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Created By:</label>
          <v-select placeholder="Created By" :options="contactList" label="name"
              :reduce="(name) => name.id" v-model="updatedBy" />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Receipt type: </label>
          <v-select
              placeholder="Select Bill Type"
              v-model="billType"
              :options="billTypeList"
              label="label"
              :reduce="label => label.value"
              multiple
          />
        </div>
      </DateQuerySetter>
    </div>

    <div class="d-flex justify-content-end px-2 mb-2">
      <button class="btn btn-primary btn-block waves-effect waves-float waves-light print-btn" @click="goToPrint">
        Print
      </button>
      <button class="btn btn-primary btn-block waves-effect waves-float waves-light" @click="exportReport">
        Export
      </button>
    </div>

    <div class="col-12 px-2">
      <ListTable :money-receipt-statement="moneyReceiptStatement"/>
    </div>
    <div class="mb-2"></div>

    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
    <Loader v-if="loading"/>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import {useRoute, useRouter} from 'vue-router';
import DateQuerySetter from '@/components/atom/DateQuerySetter';
import ListTable from '@/components/molecule/company/hospital/hospital-admin/MoneyReceiptSatementReportTable.vue';
import handleHospitalReport from "@/services/modules/hospital/report";
import {computed, inject, onMounted, ref} from "vue";
import Loader from "@/components/atom/LoaderComponent.vue";
import Pagination from "@/components/atom/Pagination.vue";
import {useStore} from "vuex";
import handleCompany from "@/services/modules/company";
import pdfPrinter from '@/services/utils/pdf/moneyReceiptStatement';
import moneyReceiptHelper from '@/services/utils/pdf/moneyReceiptHelper.js';
import handleImportTracker from "@/services/modules/procurement/importTracker";
import handleInventory from "@/services/modules/inventory";

const {fetchHome} = handleInventory()
const { fetchContacts } = handleImportTracker();
const {fetchMoneyReceiptStatement} = handleHospitalReport()
const { fetchCompanyInfo } = handleCompany();
const { formatMoneyReceiptData } = moneyReceiptHelper()
const { exportToPDF } = pdfPrinter();

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const store = useStore()
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const contactList = ref([])
const billTypeList = ref([])
const moneyReceiptStatement = ref([])
const moneyReceiptPdfRecords = ref([]);
let offset = ref(100)
const updatedBy = ref(null)
const billType = ref(null)
const companyInfo = ref({})
const isFetchAll = ref(false);

//computed
const companyId = computed(() => route.params.companyId);
const startDate = computed(() => route.query.start);
const endDate = computed(() => route.query.end);
const page = computed(() => route.query.page);

//methods
const goToPrint = async() => {
  
  const payloads = { 
    startDate: startDate.value, 
    endDate: endDate.value,
    createdBy: '',
    billType: billType.value
  };

  if(updatedBy.value){
    const user = contactList.value.find(item => item.id === updatedBy.value);
    payloads.createdBy = user.name;
  }

  isFetchAll.value = true;

  await getSalesReportGeneral().then(() => {
    if(!moneyReceiptPdfRecords.value.length) return;
    const formattedData = formatMoneyReceiptData(moneyReceiptPdfRecords.value);
    exportToPDF(companyInfo.value, formattedData, payloads)
  })

  isFetchAll.value = false;
}

const getQuery = () => {

  let query = '?company_id=' + companyId.value
  if (!!updatedBy.value) {
    query += '&user_id=' + updatedBy.value
  }
  if (!!billType.value) {
    query += '&receipt_type=' + billType.value
  }

  if (!startDate.value && !endDate.value) return query
  if (startDate.value) query += '&start_date=' + startDate.value
  if (endDate.value) query += '&end_date=' + endDate.value
  if (page.value) query += '&page=' + page.value
  if (isFetchAll.value) query += '&is_fetch_all='  + isFetchAll.value;
  query += '&offset=' + offset.value
  return query
};
const getSalesReportGeneral = async () => {
  loading.value = true;
  await fetchMoneyReceiptStatement(getQuery()).then(({status, data}) => {
    if (!status) {
      moneyReceiptStatement.value = []
      return resetPagination()
    }

    if(isFetchAll.value) {
      moneyReceiptPdfRecords.value = data;
      return;
    }

    moneyReceiptStatement.value = data.data
    if (data.data.length > 0) {
      setPagination(data)
    }
  }).catch(() => {
    loading.value = false
  }).finally(() => {
    loading.value = false
  })
};


const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};
const resetPagination = () => {
  store.commit('resetPagination')
};

const exportReport = () => {
  let token = localStorage.getItem('token');

  let url = `${process.env.VUE_APP_BASE_URL}/export/hospital/money-receipt-statements${getQuery()}&_token=${token}&is_export=1`;
  let a = document.createElement('a');
  a.setAttribute('href', url);
  a.click();
}


const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

const onPageChange = async (page) => {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  await router.push({path: route.path, query: routeQuery})
  await getSalesReportGeneral()
}

//
onMounted(async () => {
  loading.value = true

  const companyQuery = `?company_id=${companyId.value}`

  await Promise.all([
    fetchCompanyInfo(companyId.value).then((res) => {
      companyInfo.value = res.data
    }),
    fetchContacts(companyQuery).then(({status, data}) => {
      if (status) contactList.value = data
    })
  ]).then(() => {
    loading.value = false;
  }).catch(() => {
    loading.value = false
  });

  fetchHome().then(res => {
    if (res.data) {
      for (const value of Object.keys(res.data.hospital_money_receipt_types)) {
        billTypeList.value.push({
          value: value,
          label: res.data.hospital_money_receipt_types[value]
        })
      }
    }
  })
})
</script>

<style scoped>
  .print-btn {
    margin-right: 1% !important;
  }
</style>
